import styled from 'styled-components'

export const StyledSingleApartmentTable = styled.div`
    .table-prices-single {
      display: none;
      margin: 1em 0;
      width: 100%;
      max-width: 600px;
      text-align: center;
      background: #fff;
      border: 1px solid #167f92;

      tr {
          border: 1px solid #d9e4e6;
          &:nth-child(even) {
              background-color: #eaf3f3;
          }
      }

      th {
          display: none;
          border: 1px solid #d9e4e6;
          background-color: #167f92;
          color: #fff;
      }

      td {
          word-wrap: break-word;
          border: 1px solid #d9e4e6;
      }

      th,
      td {
          text-align: center;
          margin: 0.5em 1em;
          display: table-cell;
          padding: 1em;
      }
      @media screen and (max-width: 505px) {
          &.table-prices-single {
              display: table;
          }
      }

      @media screen and (max-width: 470px) {
          th, td {
              padding: 0.5em;
          }
      }
    }
`

export default StyledSingleApartmentTable