import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, defineMessages } from 'react-intl'
import { Layout, Container } from '../components/common'
import SEO from '../components/common/SEO'
import Header from '../components/theme/Header'
import Footer from '../components/theme/Footer'
import Conditions from '../components/common/Prices/conditions'
import Cancelation from '../components/common/Prices/cancelation'
import Prices from '../components/common/Prices/prices'
import TitleSection from '../components/common/TitleSection'

const MainContentHolder = styled.div`
	h2 {
		${'' /* margin: 0 0 2px;
		padding: 0;
		line-height: 40px;
		font-size: 30px;
		color: #d2c970;
		font-weight: 300; */}

		font: normal 45px/55px Open Sans;
		color: #584a3e;
		margin: 30px 0 20px;
		font-weight: 300!important;
	}

	ul {
		margin-left: 25px;

		li {
		font-size: 16px;
		list-style-type: circle;
		}
	}
`

const PricesPage = () => (
	<Layout>
		<React.Fragment>
			<SEO title="prices" />
			<Header />
			<MainContentHolder className="main-content-holder">
				<TitleSection section="prices" />
				<Conditions />
				<Cancelation />
				<Prices />
			</MainContentHolder>
			<Footer />
		</React.Fragment>
	</Layout>
)

export default PricesPage
