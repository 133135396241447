import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';

const pricesMarina = () => (
  <table className="table-prices-single">
    <thead>
      <tr>
        <th colSpan="2">
          <Link to={'/apartments/marina'}>
            <FormattedMessage id="Marina" />
          </Link>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>20.06 - 27.06</td>
        <td>
          160 <FormattedMessage id="currencyNight" />
        </td>
      </tr>
      <tr>
        <td>28.06 - 11.07</td>
        <td>
          190 <FormattedMessage id="currencyNight" />
        </td>
      </tr>
      <tr>
        <td>12.07 - 17.08</td>
        <td>
          218 <FormattedMessage id="currencyNight" />
        </td>
      </tr>
      <tr>
        <td>18.08 - 31.08</td>
        <td>
          180 <FormattedMessage id="currencyNight" />
        </td>
      </tr>
      <tr>
        <td>01.09 - 08.09</td>
        <td>
          140 <FormattedMessage id="currencyNight" />
        </td>
      </tr>
      <tr>
        <td>09.09 - 30.09</td>
        <td>
          126 <FormattedMessage id="currencyNight" />
        </td>
      </tr>
    </tbody>
  </table>
);

export default pricesMarina;
