import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PricesCoral from './pricesCoral';
import PricesComfort from './pricesComfort';
import PricesMarina from './pricesMarina';
import PricesPerfect from './pricesPerfect';
import { StyledSingleApartmentTable } from './singleApartmentsStyling';

const StyledTable = styled.table`
  margin: 1em 0;
  width: 100%;
  background: #fff;
  border: 1px solid #167f92;

  tr {
    border: 1px solid #d9e4e6;
    &:nth-child(even) {
      background-color: #eaf3f3;
    }
  }

  th {
    display: none;
    border: 1px solid #d9e4e6;
    background-color: #167f92;
    color: #fff;
  }

  td {
    word-wrap: break-word;
    border: 1px solid #d9e4e6;
  }

  th,
  td {
    text-align: center;
    margin: 0.5em 1em;
    display: table-cell;
    padding: 1em;
  }

  @media screen and (max-width: 505px) {
    &.table-prices {
      display: none;
    }
  }
`;

const Prices = () => (
  <React.Fragment>
    <h2>
      <FormattedMessage id="pricesGeneralText" />
    </h2>

    <p>
      <FormattedMessage id="pricesInformation" />
    </p>

    <StyledTable className="table-prices">
      <thead>
        <tr>
          <th>2024</th>
          <th>
            <Link to={'/apartments/coral'}>
              <FormattedMessage id="Coral" />
            </Link>
          </th>
          <th>
            <Link to={'/apartments/comfort'}>
              <FormattedMessage id="Comfort" />
            </Link>
          </th>
          <th>
            <Link to={'/apartments/marina'}>
              <FormattedMessage id="Marina" />
            </Link>
          </th>
          <th>
            <Link to={'/apartments/perfect'}>
              <FormattedMessage id="Perfect" />
            </Link>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>20.06 - 27.06</td>
          <td>
            105 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            110 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            160 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            90 <FormattedMessage id="currencyNight" />
          </td>
        </tr>
        <tr>
          <td>28.06 - 11.07</td>
          <td>
            119 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            125 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            190 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            115 <FormattedMessage id="currencyNight" />
          </td>
        </tr>
        <tr>
          <td>12.07 - 17.08</td>
          <td>
            130 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            140 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            218 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            133 <FormattedMessage id="currencyNight" />
          </td>
        </tr>
        <tr>
          <td>18.08 - 31.08</td>
          <td>
            113 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            119 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            180 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            115 <FormattedMessage id="currencyNight" />
          </td>
        </tr>
        <tr>
          <td>01.09 - 08.09</td>
          <td>
            97 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            100 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            140 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            101 <FormattedMessage id="currencyNight" />
          </td>
        </tr>
        <tr>
          <td>09.09 - 30.09</td>
          <td>
            76 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            80 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            126 <FormattedMessage id="currencyNight" />
          </td>
          <td>
            76 <FormattedMessage id="currencyNight" />
          </td>
        </tr>
      </tbody>
    </StyledTable>

    <StyledSingleApartmentTable>
      <PricesCoral />
    </StyledSingleApartmentTable>
    <StyledSingleApartmentTable>
      <PricesComfort />
    </StyledSingleApartmentTable>
    <StyledSingleApartmentTable>
      <PricesMarina />
    </StyledSingleApartmentTable>
    <StyledSingleApartmentTable>
      <PricesPerfect />
    </StyledSingleApartmentTable>
  </React.Fragment>
);

export default Prices;
